<template>
<div>
  <div class="borkersFormOne">
    <div class="textBox">
      <div class="spanBox1">
        <span>监管及许可信息聲明</span>
      </div>
    </div>
    <div class="textBox">
      <div class="spanBox2">
        <span>
          要使用Finger平台的軟體，您的公司必须在一个或多个国家的监管机构处注册以便向客户提供证券、衍生品或其他金融产品的经纪服务。<br/><br/>
          列出您公司获取许可或注册从而为客户提供经纪服务的国家。 只需列出您的公司获取许可及您的客户实际通过Finger进行交易的国家。 比如，若您的公司获取了某个国家的许可，但您的客户不会与Finger进行交易，那么请不要列出这个国家。
        </span>
      </div>
    </div>
    <div class="textBox">
      <div class="spanBox3">
        <span>
          請選擇符合您的條件  
        </span>
      </div>
    </div>
    <div class="textBox">
      <div class="spanBox4">
        <div class="leftBox">
          <input class="checkbox" type="checkbox" v-model="inputValue1">
        </div>
        <div class="rightBox">
          <span>
            您是否有该申请适用于未在任何监管机构获批或注册的经纪公司。
          </span>
        </div>
      </div>
    </div>
    <div class="textBox">
      <div class="spanBox4">
        <div class="leftBox">
          <input class="checkbox" type="checkbox" v-model="inputValue2">
        </div>
        <div class="rightBox">
          <span>
            该申请适用于在单个国家获批或注册的经纪公司。
          </span>
        </div>
      </div>
    </div>
    <div class="textBox">
      <div class="spanBox4">
        <div class="leftBox">
          <input class="checkbox" type="checkbox" v-model="inputValue3">
        </div>
        <div class="rightBox">
          <span>
            该申请适用于在多个国家获批或注册的经纪公司。
          </span>
        </div>
      </div>
    </div>
    <div class="textBox">
      <div class="spanBox4">
        <div class="leftBox">
          <input class="checkbox" type="checkbox" v-model="inputValue4">
        </div>
        <div class="rightBox">
          <span>
            主要注册/许可国家或地区
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="warringText" v-if="chickShow">
    <span>{{warring}}</span>
  </div>
</div>
</template>

<script>
export default {
  name:'BorkersFormOne',
  components: {

  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      inputValue1:false,
      inputValue2:false,
      inputValue3:false,
      inputValue4:false,
      isFormOk:false,
      warring:"*You need to chick it all"
    }
  },
  methods:{
    changPage(){
      if(this.inputValue1 == true 
      && this.inputValue2 == true 
      && this.inputValue3 == true 
      && this.inputValue4 == true ){
        this.isFormOk = true
        this.warring = ""
        this.$emit('isFormOk', this.isFormOk)
      }
      else{
        this.isFormOk = false
        this.warring = "*You need to chick it all"
        this.$emit('isFormOk', this.isFormOk)
      }
    }
  },
  watch:{
    "inputValue1"(){
      this.changPage()
    },
    "inputValue2"(){
      this.changPage()
    },
    "inputValue3"(){
      this.changPage()
    },
    "inputValue4"(){
      this.changPage()
    },
  }
}
</script>

<style scoped>
.borkersFormOne{
  display: grid;
  grid-template-rows:67px 38px 44px 168px 30px 67px 38px 15px 22px 5px 22px 5px 22px 5px 22px ;
}
.textBox{
  display: flex;
}
.textBox:nth-child(1){
  grid-row: 2/3;
}
.textBox:nth-child(2){
  grid-row: 4/5;
}
.textBox:nth-child(3){
  grid-row: 7/8;
}
.textBox:nth-child(4){
  grid-row: 9/10;
}
.textBox:nth-child(5){
  grid-row: 11/12;
}
.textBox:nth-child(6){
  grid-row: 13/14;
}
.textBox:nth-child(7){
  grid-row: 15/16;
}
.spanBox1, .spanBox2, .spanBox3, .spanBox4{
  font-family: Inter;
  margin: 0 auto;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.spanBox1{
  font-size: 38px;
}
.spanBox2{
  max-width: 847px;
  font-size: 22px;
}
.spanBox3{
  font-size: 28px;
}
.spanBox4{
  font-size: 22px;
  width: 700px;
}
.leftBox{
  height: 100%;
  display: inline-block;
}
.rightBox{
  height: 100%;
  font-size: 22px;
  display: inline-block;
}
.checkbox{
  height: 24px;
  width: 24px;
}
.warringText{
  color: rgb(255, 0, 0);
  text-align: center;
}
</style>
