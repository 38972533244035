<template>
  <div class="borkersFormThree">
    <div class="leftAndRightBox">
      <div class="itemBigBox">
        <div class="itemBox">
          <div class="tittleNameBox">
            <span>公司名稱</span>
          </div>
          <div class="componentsBox">
            <Validator type="text" :value="form.companyName" :show="chickShow" @isCheckOkValue="companyNameCheck">
              <input-text placeholder="303 Meadowview Drive" v-model="form.companyName"/>
            </Validator>
          </div>
        </div>
        <div class="itemBox">
          <div class="tittleNameBox">
            <span>業務描述</span>
          </div>
          <div class="componentsBox">
            <Validator :value="form.description" :show="chickShow" @isCheckOkValue="descriptionCheck">
              <input-text placeholder="303 Meadowview Drive" v-model="form.description"/>
            </Validator>
          </div>
        </div>
        <div class="itemBox">
          <div class="tittleNameBox">
            <span>辦公地址</span>
          </div>
          <div class="componentsBox">
            <Validator :value="form.officeAddress" :show="chickShow" @isCheckOkValue="officeAddressCheck">
              <input-text placeholder="303 Meadowview Drive" v-model="form.officeAddress"/>
            </Validator>
          </div>
        </div>
        <div class="itemBox">
          <div class="item">
            <div class="tittleNameBox">
              <span>Zip</span>
            </div>
            <div class="componentsBox">
              <Validator type="number" :value="form.zip" :show="chickShow" @isCheckOkValue="zipCheck">
                <input-text placeholder="24175" v-model="form.zip"/>
              </Validator>
            </div>
          </div>
          <div class="item">
            <div class="tittleNameBox">
              <span>City</span>
            </div>
            <div class="componentsBox">
              <Validator type="text" :value="form.city" :show="chickShow" @isCheckOkValue="cityCheck">
                <input-text placeholder="Troutville" v-model="form.city"/>
              </Validator>
            </div>
          </div>
          <div class="item">
            <div class="tittleNameBox">
              <span>State</span>
            </div>
            <div class="componentsBox">
              <Validator type="text" :value="form.state" :show="chickShow" @isCheckOkValue="stateCheck">
                <input-text placeholder="VA" v-model="form.state"/>
              </Validator>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="tittleNameBox">
            <span>公司官網(非必填)</span>
          </div>
          <div class="componentsBox">
            <Validator type="notRequired" :value="form.internetAddress" :show="chickShow" @isCheckOkValue="internetAddressCheck">
              <input-text placeholder="303 Meadowview Drive" v-model="form.internetAddress"/>
            </Validator>
          </div>
        </div>
      </div>
      <div class="itemBigBox">
        <div class="itemBox">
          <div class="tittleNameBox">
            <span>聯繫電話</span>
          </div>
          <div class="contactNumberBox">
            <div class="item2">
              <div class="componentsBox2">
                <Validator type="drowDownMeun" defaultName="Select" :titleName="form.phoneTitle" :show="chickShow" @isCheckOkValue="phoneTitleCheck">
                  <select-menu :title="form.phoneTitle" :width='80'>
                    <option-menu v-for="item in phoneList" :key="item.id" @click="phoneTitle(item)">{{item.phonecode}}</option-menu>
                  </select-menu>
                </Validator>
              </div>
            </div>
            <div class="item2">
              <div class="componentsBox3">
                <Validator type="number" :value="form.phoneNumber" :show="chickShow" @isCheckOkValue="phoneNumberCheck">
                  <input-text placeholder="Plese fill in" v-model="form.phoneNumber"/>
                </Validator>
              </div>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="tittleNameBox">
            <span>稅務居住地</span>
          </div>
          <div class="componentsBox">
            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.taxResidence" :show="chickShow" @isCheckOkValue="taxResidenceCheck">
              <select-menu :title="form.taxResidence" :width='250'>
                <option-menu v-for="item in taxResidenceList" :key="item.id" @click="taxResidence(item)">{{item.name}}</option-menu>
              </select-menu>
            </Validator>
          </div>
        </div>
        <div class="itemBox">
          <div class="tittleNameBox">
            <span>稅號</span>
          </div>
          <div class="componentsBox">
            <Validator :value="form.taxNumber" :show="chickShow" @isCheckOkValue="taxNumberCheck">
              <input-text placeholder="303 Meadowview Drive" v-model="form.taxNumber"/>
            </Validator>
          </div>
        </div>
        <div class="itemBox">
          <div class="leftBox">
            <div class="tittleNameBox">
              <span>企業登記證</span>
            </div>
            <div class="inputBox">
              <div class="fileUpLoadBox">
                <el-upload
                  :on-success="onUploadSuccessImg1"
                  :before-upload="beforeAvatarUpload1"
                  :multiple="false"
                  :action="avatarUrl"
                  :data="{module:'idCard'}"
                  :limit="1"
                  class="uploadbtn">
                  <ElUploadBtnStyle>
                    <template v-slot:img>
                      <img src="@/assets/xuImg/idCard.png" v-if="!imgUrlBoolen1">
                      <img :src="form.imgUrl1" v-if="imgUrlBoolen1">
                    </template>
                  </ElUploadBtnStyle>
                </el-upload>
              </div>
              <div class="warringText" v-if="chickShow">
                <span>{{imgwarring1}}</span>
              </div>
            </div>
          </div>
          <div class="rightBox">
            <div class="tittleNameBox">
              <span>稅務登記證</span>
            </div>
            <div class="inputBox">
              <div class="fileUpLoadBox">
                <el-upload
                  :on-success="onUploadSuccessImg2"
                  :before-upload="beforeAvatarUpload2"
                  :multiple="false"
                  :action="avatarUrl"
                  :data="{module:'idCard'}"
                  :limit="1"
                  class="uploadbtn">
                  <ElUploadBtnStyle>
                    <template v-slot:img>
                      <img src="@/assets/xuImg/idCard.png" v-if="!imgUrlBoolen2">
                      <img :src="form.imgUrl2" v-if="imgUrlBoolen2">
                    </template>
                  </ElUploadBtnStyle>
                </el-upload>
              </div>
              <div class="warringText" v-if="chickShow">
                <span>{{imgwarring2}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/XuComponents/form/InputText'
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import {smsCountryList,CountryList} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
import ElUploadBtnStyle from '@/components/XuComponents/fileUpLoad/ElUploadBtnStyle'
export default {
  name:'BorkersFormThree',
  components: {
    InputText,
    SelectMenu,
    OptionMenu,
    Validator,
    ElUploadBtnStyle,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      phoneList:[],
      taxResidenceList:[],

      //圖片
      avatarUrl:"http://13.114.63.198:9119/admin/oss/file/upload",
      imgwarring1:"*必須上傳檔案",
      imgwarring2:"*必須上傳檔案",
      imgUrlBoolen1:false,
      imgUrlBoolen2:false,

      form:{
        companyName:"",
        description:"",
        internetAddress:"",
        officeAddress:"",
        zip:"",
        city:"",
        state:"",
        phoneTitle:"Select",
        phoneNumber:"",
        taxResidence:"Select",
        taxNumber:"",
        imgUrl1:"",
        imgUrl2:"",

      },
      isCheckAllOkForm:{
        companyNameCheck:false,
        descriptionCheck:false,
        officeAddressCheck:false,
        zipCheck:false,
        cityCheck:false,
        stateCheck:false,
        internetAddressCheck:false,
        phoneTitleCheck:false,
        phoneNumberCheck:false,
        taxResidenceCheck:false,
        taxNumberCheck:false,

        imgCheck1:false,
        imgCheck2:false,
      },
      isFormTwoOk:false,
    }
  },
  methods:{
    phoneTitle(item){
      this.form.phoneTitle = item.phonecode
    },
    taxResidence(item){
      this.form.taxResidence = item.name
    },
    Get(){
      smsCountryList().then(res=>{
        this.phoneList = res.data.list
      })
      CountryList().then(res=>{
        this.taxResidenceList = res.data.items
      })
    },

    //圖片上傳
    onUploadSuccessImg1(response){
      if(response.code==20000){
        this.form.imgUrl1 = response.data.url
        this.imgUrlBoolen1 = true
      }
      else{
        this.imgUrlBoolen1 = false
      }
    },
    onUploadSuccessImg2(response){
      if(response.code==20000){
        this.form.imgUrl2 = response.data.url
        this.imgUrlBoolen2 = true
      }
      else{
        this.imgUrlBoolen2 = false
      }
    },
    //圖片上傳前表單校驗
    beforeAvatarUpload1(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.imgwarring1="*上傳證件只能是 JPG 格式!"
        this.isCheckAllOkForm.imgCheck1 = false
      }
      else if(!isLt2M){
        this.imgwarring1="*上傳證件大小不能超過 2MB!"
        this.isCheckAllOkForm.imgCheck1 = false
      }
      else{
        this.imgwarring1=""
        this.isCheckAllOkForm.imgCheck1 = true
      }
      this.changPage()
      return isJPG && isLt2M;
    },
    beforeAvatarUpload2(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.imgwarring2="*上傳證件只能是 JPG 格式!"
        this.isCheckAllOkForm.imgCheck2 = false
      }
      else if(!isLt2M){
        this.imgwarring2="*上傳證件大小不能超過 2MB!"
        this.isCheckAllOkForm.imgCheck2 = false
      }
      else{
        this.imgwarring2=""
        this.isCheckAllOkForm.imgCheck2 = true
      }
      this.changPage()
      return isJPG && isLt2M;
    },

    companyNameCheck(CheckBoolen){
      this.isCheckAllOkForm.companyNameCheck = CheckBoolen
      this.changPage()
    },
    descriptionCheck(CheckBoolen){
      this.isCheckAllOkForm.descriptionCheck = CheckBoolen
      this.changPage()
    },
    officeAddressCheck(CheckBoolen){
      this.isCheckAllOkForm.officeAddressCheck = CheckBoolen
      this.changPage()
    },
    zipCheck(CheckBoolen){
      this.isCheckAllOkForm.zipCheck = CheckBoolen
      this.changPage()
    },
    cityCheck(CheckBoolen){
      this.isCheckAllOkForm.cityCheck = CheckBoolen
      this.changPage()
    },
    stateCheck(CheckBoolen){
      this.isCheckAllOkForm.stateCheck = CheckBoolen
      this.changPage()
    },
    internetAddressCheck(CheckBoolen){
      this.isCheckAllOkForm.internetAddressCheck = CheckBoolen
      this.changPage()
    },
    phoneTitleCheck(CheckBoolen){
      this.isCheckAllOkForm.phoneTitleCheck = CheckBoolen
      this.changPage()
    },
    phoneNumberCheck(CheckBoolen){
      this.isCheckAllOkForm.phoneNumberCheck = CheckBoolen
      this.changPage()
    },
    taxResidenceCheck(CheckBoolen){
      this.isCheckAllOkForm.taxResidenceCheck = CheckBoolen
      this.changPage()
    },
    taxNumberCheck(CheckBoolen){
      this.isCheckAllOkForm.taxNumberCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.companyNameCheck == true 
      && this.isCheckAllOkForm.descriptionCheck == true 
      && this.isCheckAllOkForm.officeAddressCheck == true 
      && this.isCheckAllOkForm.zipCheck == true 
      && this.isCheckAllOkForm.cityCheck == true 
      && this.isCheckAllOkForm.stateCheck == true 
      && this.isCheckAllOkForm.internetAddressCheck == true 
      && this.isCheckAllOkForm.phoneTitleCheck == true 
      && this.isCheckAllOkForm.phoneNumberCheck == true 
      && this.isCheckAllOkForm.taxResidenceCheck == true 
      && this.isCheckAllOkForm.taxNumberCheck == true 
      && this.isCheckAllOkForm.imgCheck1 == true 
      && this.isCheckAllOkForm.imgCheck2 == true ){
        this.isFormTwoOk = true
        this.$emit('isFormOk', this.isFormTwoOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormTwoOk = false
        this.$emit('isFormOk', this.isFormTwoOk)
      }
    },
  },
  mounted(){
    this.Get()
  }
}
</script>

<style scoped>
.borkersFormThree{
  width: 100%;
  height: 100%;
  display: flex;
}
.leftAndRightBox{
  margin: 0 auto;
  height: 100%;
  display: flex;
}
.itemBigBox:nth-child(1){
  margin-right: 187px;
}
.itemBox{
  width: 100%;
  margin-bottom: 25px;
}
.tittleNameBox{
  width: 100%;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.componentsBox{
  width: 100%;
}
.item{
  display: inline-block;
  margin-right: 30px;
}
.item .tittleNameBox{
  font-size: 20px;
}
.item:nth-child(1){
  width: 57px;
}
.item:nth-child(2){
  width: 171px;
}
.item:nth-child(3){
  width: 72px;
  margin-right: 0px;
}
.itemBox:last-child{
  margin-bottom: 0px;
}
.itemBox:first-child{
  margin-top: 40px;
}
.item2{
  display: inline-block;
}
.item2:first-child{
  margin-right: 30px;
}
.componentsBox2{
  width: 80px;
}
.componentsBox3{
  width: 240px;
}
.itemBigBox:nth-child(2) .itemBox:last-child{
  display: flex;
}
.leftBox{
  margin-right: 34px;
}
.fileUpLoadBox{
  width: 246px;
  height: 138px;
  border-radius: 7px;
  overflow: hidden;
}
.warringText{
  text-align: right;
  margin-left: 20px;

  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
}
.uploadbtn{
  width: 100%;
  height: 100%;
}
img{
  width: 100%;
}
</style>
