<template>
  <div class="borkerFormFour">
    <div class="textBigBox">
      <div class="leftBox">
        <div class="tittleText">
          <span>公司已在监管机构/自律监管机构注册/获得许可？</span>
        </div>
        <div class="ChickBtnBox">
          <ChickBtn falseName="No" trueName="Yes" @backValue="ChickBtnValue1"/>
        </div>
        <div class="warringText" v-if="chickShow">
          <span>{{warring}}</span>
        </div>
        <div class="drowDownMeunBox">
          <DrowDownMeun :meunHeight="420" titleName="經紀人牌照" :down="true">
            <template v-slot:meun>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>頒發國家</span>
                </div>
                <div class="inputBox">
                  <Validator type="drowDownMeun" defaultName="Select" :titleName="form.countryName" :show="chickShow" @isCheckOkValue="countryNameCheck">
                    <select-menu :title="form.countryName" :width='152'>
                      <option-menu v-for="item in countryNameList" :key="item.id" @click="countryName(item)">{{item.name}}</option-menu>
                    </select-menu>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>牌照編號</span>
                </div>
                <div class="inputBox">
                  <Validator :value="form.licenseNumber" :show="chickShow" @isCheckOkValue="licenseNumberCheck">
                    <input-text placeholder="University of Cambridge" v-model="form.licenseNumber"/>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>頒發機構名稱</span>
                </div>
                <div class="inputBox">
                  <Validator type="text" :value="form.issuingAuthorityName" :show="chickShow" @isCheckOkValue="issuingAuthorityNameCheck">
                    <input-text placeholder="University of Cambridge" v-model="form.issuingAuthorityName"/>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>經紀人類型</span>
                </div>
                <div class="inputBox">
                  <Validator type="drowDownMeun" defaultName="Select" :titleName="form.brokerType" :show="chickShow" @isCheckOkValue="brokerTypeCheck">
                    <select-menu :title="form.brokerType" :width='152'>
                      <option-menu v-for="item in brokerTypeList" :key="item.id" @click="brokerType(item)">{{item.enName}}</option-menu>
                    </select-menu>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>執照照片</span>
                </div>
                <div class="inputBox">
                  <div class="fileUpLoadBox">
                    <el-upload
                      :on-success="onUploadSuccessImg"
                      :before-upload="beforeAvatarUpload"
                      :multiple="false"
                      :action="avatarUrl"
                      :data="{module:'avatar'}"
                      :limit="1"
                      class="uploadbtn">
                      <ElUploadBtnStyle>
                        <template v-slot:img>
                          <img src="@/assets/xuImg/Rectangle138.png" v-if="!imgUrlBoolen1">
                          <img :src="form.imgUrl1" v-if="imgUrlBoolen1">
                        </template>
                      </ElUploadBtnStyle>
                    </el-upload>
                  </div>
                  <div class="warringText" v-if="chickShow">
                    <span>{{imgwarring1}}</span>
                  </div>
                </div>
              </div>
            </template>
          </DrowDownMeun>
        </div>
      </div>
      <div class="rightBox">
        <div class="drowDownMeunBox">
          <DrowDownMeun :meunHeight="420" titleName="分析師牌照" :down="true">
            <template v-slot:meun>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>頒發國家</span>
                </div>
                <div class="inputBox">
                  <Validator type="drowDownMeun" defaultName="Select" :titleName="form.countryNameAnalyst" :show="chickShow" @isCheckOkValue="countryNameAnalystCheck">
                    <select-menu :title="form.countryNameAnalyst" :width='152'>
                      <option-menu v-for="item in countryNameListAnalyst" :key="item.id" @click="countryNameAnalyst(item)">{{item.name}}</option-menu>
                    </select-menu>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>牌照編號</span>
                </div>
                <div class="inputBox">
                  <Validator :value="form.licenseNumberAnalyst" :show="chickShow" @isCheckOkValue="licenseNumberAnalystCheck">
                    <input-text placeholder="University of Cambridge" v-model="form.licenseNumberAnalyst"/>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>頒發機構名稱</span>
                </div>
                <div class="inputBox">
                  <Validator type="text" :value="form.issuingAuthorityNameAnalyst" :show="chickShow" @isCheckOkValue="issuingAuthorityNameAnalystCheck">
                    <input-text placeholder="University of Cambridge" v-model="form.issuingAuthorityNameAnalyst"/>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>經紀人類型</span>
                </div>
                <div class="inputBox">
                  <Validator type="drowDownMeun" defaultName="Select" :titleName="form.brokerTypeAnalyst" :show="chickShow" @isCheckOkValue="brokerTypeAnalystCheck">
                    <select-menu :title="form.brokerTypeAnalyst" :width='152'>
                      <option-menu v-for="item in brokerTypeListAnalyst" :key="item.id" @click="brokerTypeAnalyst(item)">{{item.enName}}</option-menu>
                    </select-menu>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>執照照片</span>
                </div>
                <div class="inputBox">
                  <div class="fileUpLoadBox">
                    <el-upload
                      :on-success="onUploadSuccessImg2"
                      :before-upload="beforeAvatarUpload2"
                      :multiple="false"
                      :action="avatarUrl"
                      :data="{module:'avatar'}"
                      :limit="1"
                      class="uploadbtn">
                      <ElUploadBtnStyle>
                        <template v-slot:img>
                          <img src="@/assets/xuImg/Rectangle138.png" v-if="!imgUrlBoolen2">
                          <img :src="form.imgUrl2" v-if="imgUrlBoolen2">
                        </template>
                      </ElUploadBtnStyle>
                    </el-upload>
                  </div>
                  <div class="warringText" v-if="chickShow">
                    <span>{{imgwarring2}}</span>
                  </div>
                </div>
              </div>
            </template>
          </DrowDownMeun>
        </div>
        <div class="drowDownMeunBox">
          <DrowDownMeun :meunHeight="420" titleName="其他" :down="false">
            <template v-slot:meun>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>頒發國家</span>
                </div>
                <div class="inputBox">
                  <Validator type="drowDownMeun" defaultName="Select" :titleName="form.countryNameOther" :show="chickShow" @isCheckOkValue="countryNameOtherCheck">
                    <select-menu :title="form.countryNameOther" :width='152'>
                      <option-menu v-for="item in countryNameListOther" :key="item.id" @click="countryNameOther(item)">{{item.name}}</option-menu>
                    </select-menu>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>牌照編號</span>
                </div>
                <div class="inputBox">
                  <Validator :value="form.licenseNumberOther" :show="chickShow" @isCheckOkValue="licenseNumberOtherCheck">
                    <input-text placeholder="University of Cambridge" v-model="form.licenseNumberOther"/>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>頒發機構名稱</span>
                </div>
                <div class="inputBox">
                  <Validator type="text" :value="form.issuingAuthorityNameOther" :show="chickShow" @isCheckOkValue="issuingAuthorityNameOtherCheck">
                    <input-text placeholder="University of Cambridge" v-model="form.issuingAuthorityNameOther"/>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>經紀人類型</span>
                </div>
                <div class="inputBox">
                  <Validator type="drowDownMeun" defaultName="Select" :titleName="form.brokerTypeOther" :show="chickShow" @isCheckOkValue="brokerTypeOtherCheck">
                    <select-menu :title="form.brokerTypeOther" :width='152'>
                      <option-menu v-for="item in brokerTypeListOther" :key="item.id" @click="brokerTypeOther(item)">{{item.enName}}</option-menu>
                    </select-menu>
                  </Validator>
                </div>
              </div>
              <div class="itemsBox">
                <div class="itemsName">
                  <span>執照照片</span>
                </div>
                <div class="inputBox">
                  <div class="fileUpLoadBox">
                    <el-upload
                      :on-success="onUploadSuccessImg3"
                      :before-upload="beforeAvatarUpload3"
                      :multiple="false"
                      :action="avatarUrl"
                      :data="{module:'avatar'}"
                      :limit="1"
                      class="uploadbtn">
                      <ElUploadBtnStyle>
                        <template v-slot:img>
                          <img src="@/assets/xuImg/Rectangle138.png" v-if="!imgUrlBoolen3">
                          <img :src="form.imgUrl3" v-if="imgUrlBoolen3">
                        </template>
                      </ElUploadBtnStyle>
                    </el-upload>
                  </div>
                  <div class="warringText" v-if="chickShow">
                    <span>{{imgwarring3}}</span>
                  </div>
                </div>
              </div>
            </template>
          </DrowDownMeun>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChickBtn from '@/components/button/ChickBtn'
import DrowDownMeun from '@/components/XuComponents/drowDownMeun/DrowDownMeun'
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import InputText from '@/components/XuComponents/form/InputText'
import {CountryList,getDictById} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
import ElUploadBtnStyle from '@/components/XuComponents/fileUpLoad/ElUploadBtnStyle'
export default {
  name:'BorkerFormFour',
  components: {
    ChickBtn,
    DrowDownMeun,
    SelectMenu,
    OptionMenu,
    InputText,
    Validator,
    ElUploadBtnStyle,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      warring:"*You need to chick it all",
      countryNameList:[],
      brokerTypeList:[],
      countryNameListAnalyst:[],
      brokerTypeListAnalyst:[],
      countryNameListOther:[],
      brokerTypeListOther:[],

      //圖片
      avatarUrl:"http://13.114.63.198:9119/admin/oss/file/upload",
      imgwarring1:"*必須上傳檔案",
      imgwarring2:"*必須上傳檔案",
      imgwarring3:"*必須上傳檔案",
      imgUrlBoolen1:false,
      imgUrlBoolen2:false,
      imgUrlBoolen3:false,

      form:{
        qualificationCertificate:false,
        countryName:"Select",
        licenseNumber:"",
        issuingAuthorityName:"",
        brokerType:"Select",
        countryNameAnalyst:"Select",
        licenseNumberAnalyst:"",
        issuingAuthorityNameAnalyst:"",
        brokerTypeAnalyst:"Select",
        countryNameOther:"Select",
        licenseNumberOther:"",
        issuingAuthorityNameOther:"",
        brokerTypeOther:"Select",
        imgUrl1:"",
        imgUrl2:"",
        imgUrl3:"",
      },
      isCheckAllOkForm1:{
        countryNameCheck:false,
        licenseNumberCheck:false,
        issuingAuthorityNameCheck:false,
        brokerTypeCheck:false,

        countryNameAnalystCheck:false,
        licenseNumberAnalystCheck:false,
        issuingAuthorityNameAnalystCheck:false,
        brokerTypeAnalystCheck:false,

        countryNameOtherCheck:false,
        licenseNumberOtherCheck:false,
        issuingAuthorityNameOtherCheck:false,
        brokerTypeOtherCheck:false,

        img1Check:false,
        img2Check:false,
        img3Check:false,
      },
      isFormOk1:false,
      isFormOk2:false,
      isFormOk3:false,

      isFormAllOk:false,

      isChickAllOk:false,
    }
  },
  methods:{
    
    ChickBtnValue1(item){
      this.form.qualificationCertificate = item
      if(item == true){
        this.warring =""
      }
      else{
        this.warring ="*You need to chick it all"
      }
      this.threePickOne()
    },
    countryName(item){
      this.form.countryName = item.name
    },
    brokerType(item){
      this.form.brokerType = item.enName
    },
    countryNameAnalyst(item){
      this.form.countryNameAnalyst = item.name
    },
    brokerTypeAnalyst(item){
      this.form.brokerTypeAnalyst = item.enName
    },
    countryNameOther(item){
      this.form.countryNameOther = item.name
    },
    brokerTypeOther(item){
      this.form.brokerTypeOther = item.enName
    },
    countryNameListGet(){
      CountryList().then(res =>{
        this.countryNameList = res.data.items
        this.countryNameListAnalyst = res.data.items
        this.countryNameListOther = res.data.items
      })
    },
    brokerTypeListGet(){
      getDictById(15000).then(res =>{
        this.brokerTypeList = res.data.list
        this.brokerTypeListAnalyst = res.data.list
        this.brokerTypeListOther = res.data.list
      })
    },

    //圖片上傳
    onUploadSuccessImg(response){
      if(response.code==20000){
        this.form.imgUrl1 = response.data.url
        this.imgUrlBoolen1 = true
      }
      else{
        this.imgUrlBoolen1 = false
      }
    },
    onUploadSuccessImg2(response){
      if(response.code==20000){
        this.form.imgUrl2 = response.data.url
        this.imgUrlBoolen2 = true
      }
      else{
        this.imgUrlBoolen2 = false
      }
    },
    onUploadSuccessImg3(response){
      if(response.code==20000){
        this.form.imgUrl3 = response.data.url
        this.imgUrlBoolen3 = true
      }
      else{
        this.imgUrlBoolen3 = false
      }
    },
    //圖片上傳前表單校驗
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.imgwarring1="*上傳證件只能是 JPG 格式!"
        this.isCheckAllOkForm1.img1Check = false
      }
      else if(!isLt2M){
        this.imgwarring1="*上傳證件大小不能超過 2MB!"
        this.isCheckAllOkForm1.img1Check = false
      }
      else{
        this.imgwarring1=""
        this.isCheckAllOkForm1.img1Check = true
      }
      this.changPage1()
      return isJPG && isLt2M;
    },
    beforeAvatarUpload2(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.imgwarring2="*上傳證件只能是 JPG 格式!"
        this.isCheckAllOkForm1.img2Check = false
      }
      else if(!isLt2M){
        this.imgwarring2="*上傳證件大小不能超過 2MB!"
        this.isCheckAllOkForm1.img2Check = false
      }
      else{
        this.imgwarring2=""
        this.isCheckAllOkForm1.img2Check = true
      }
      this.changPage2()
      return isJPG && isLt2M;
    },
    beforeAvatarUpload3(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.imgwarring3="*上傳證件只能是 JPG 格式!"
        this.isCheckAllOkForm1.img3Check = false
      }
      else if(!isLt2M){
        this.imgwarring3="*上傳證件大小不能超過 2MB!"
        this.isCheckAllOkForm1.img3Check = false
      }
      else{
        this.imgwarring3=""
        this.isCheckAllOkForm1.img3Check = true
      }
      this.changPage3()
      return isJPG && isLt2M;
    },

    //表單校驗
    countryNameCheck(CheckBoolen){
      this.isCheckAllOkForm1.countryNameCheck = CheckBoolen
      this.changPage1()
    },
    licenseNumberCheck(CheckBoolen){
      this.isCheckAllOkForm1.licenseNumberCheck = CheckBoolen
      this.changPage1()
    },
    issuingAuthorityNameCheck(CheckBoolen){
      this.isCheckAllOkForm1.issuingAuthorityNameCheck = CheckBoolen
      this.changPage1()
    },
    brokerTypeCheck(CheckBoolen){
      this.isCheckAllOkForm1.brokerTypeCheck = CheckBoolen
      this.changPage1()
    },
    changPage1(){
      if(this.isCheckAllOkForm1.countryNameCheck == true 
      && this.isCheckAllOkForm1.licenseNumberCheck == true 
      && this.isCheckAllOkForm1.issuingAuthorityNameCheck == true 
      && this.isCheckAllOkForm1.brokerTypeCheck == true 
      && this.isCheckAllOkForm1.img1Check == true ){
        this.isFormOk1 = true
      }
      else{
        this.isFormOk1 = false
      }
      this.threePickOne()
    },
    countryNameAnalystCheck(CheckBoolen){
      this.isCheckAllOkForm1.countryNameAnalystCheck = CheckBoolen
      this.changPage2()
    },
    licenseNumberAnalystCheck(CheckBoolen){
      this.isCheckAllOkForm1.licenseNumberAnalystCheck = CheckBoolen
      this.changPage2()
    },
    issuingAuthorityNameAnalystCheck(CheckBoolen){
      this.isCheckAllOkForm1.issuingAuthorityNameAnalystCheck = CheckBoolen
      this.changPage2()
    },
    brokerTypeAnalystCheck(CheckBoolen){
      this.isCheckAllOkForm1.brokerTypeAnalystCheck = CheckBoolen
      this.changPage2()
    },
    changPage2(){
      if(this.isCheckAllOkForm1.countryNameAnalystCheck == true 
      && this.isCheckAllOkForm1.licenseNumberAnalystCheck == true 
      && this.isCheckAllOkForm1.issuingAuthorityNameAnalystCheck == true 
      && this.isCheckAllOkForm1.brokerTypeAnalystCheck == true 
      && this.isCheckAllOkForm1.img2Check == true ){
        this.isFormOk2 = true
      }
      else{
        this.isFormOk2 = false
      }
      this.threePickOne()
    },
    countryNameOtherCheck(CheckBoolen){
      this.isCheckAllOkForm1.countryNameOtherCheck = CheckBoolen
      this.changPage3()
    },
    licenseNumberOtherCheck(CheckBoolen){
      this.isCheckAllOkForm1.licenseNumberOtherCheck = CheckBoolen
      this.changPage3()
    },
    issuingAuthorityNameOtherCheck(CheckBoolen){
      this.isCheckAllOkForm1.issuingAuthorityNameOtherCheck = CheckBoolen
      this.changPage3()
    },
    brokerTypeOtherCheck(CheckBoolen){
      this.isCheckAllOkForm1.brokerTypeOtherCheck = CheckBoolen
      this.changPage3()
    },
    changPage3(){
      if(this.isCheckAllOkForm1.countryNameOtherCheck == true 
      && this.isCheckAllOkForm1.licenseNumberOtherCheck == true 
      && this.isCheckAllOkForm1.issuingAuthorityNameOtherCheck == true 
      && this.isCheckAllOkForm1.brokerTypeOtherCheck == true 
      && this.isCheckAllOkForm1.img3Check == true ){
        this.isFormOk3 = true
      }
      else{
        this.isFormOk3 = false
      }
      this.threePickOne()
    },
    threePickOne(){
      if(this.isFormOk1 == true 
      || this.isFormOk2 == true 
      || this.isFormOk3 == true ){
        this.isFormAllOk = true
      }
      else{
        this.isFormAllOk = false
      }

      if(this.isFormAllOk == true
      && this.form.qualificationCertificate == true){
        this.isChickAllOk = true
        this.$emit('isFormOk', this.isChickAllOk)
        this.$emit('form', this.form)
      }
      else{
        this.isChickAllOk = false
        this.$emit('isFormOk', this.isChickAllOk)
      }
    }
  },
  mounted(){
    this.countryNameListGet()
    this.brokerTypeListGet()
  },
  watch:{

  }
}
</script>

<style scoped>
.borkerFormFour{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}
.textBigBox{
  height: 100%;
  margin: 0 auto;
  /* border: 1px red solid; */
  display: flex;
}
.leftBox{
  margin-right: 152px;
  /* border: 1px red solid; */
}
.tittleText{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 14px;
  padding-left: 20px;
  padding-top: 25px;
}
.ChickBtnBox{
  width: 100px;
  height: 40px;
  margin-bottom: 0px;
  padding-left: 20px;
}
.drowDownMeunBox{
  padding-top: 25px;
  width: 418px;
  /* border: 1px red solid; */
}
.itemsBox{
  width: 100%;
  /* border: 1px red solid; */
  display: flex;
}
.itemsName{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-right: 40px;
  padding-left: 20px;
}
.itemsBox:nth-child(1){
  margin-bottom: 5px;
}
.itemsBox:nth-child(2){
  margin-bottom: 0;
}
.itemsBox:nth-child(3){
  margin-bottom: 0;
}
.itemsBox:nth-child(4){
  margin-bottom: 30px;
}
.fileUpLoadBox{
  width: 246px;
  height: 138px;
  border-radius: 7px;
  overflow: hidden;
}
.warringText{
  text-align: right;
  margin-left: 20px;

  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
}
.uploadbtn{
  width: 100%;
  height: 100%;
}
img{
  width: 100%;
}
</style>
