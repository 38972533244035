<template>
  <div class="borkersFormTwo">
    <div class="textBox">
      <div class="spanBox1">
        <div class="item1">
          <span>公司註冊國家</span>
        </div>
        <div class="item2">
          <Validator type="drowDownMeun" defaultName="Select" :titleName="form.countryName" :show="chickShow" @isCheckOkValue="countryNameCheck">
            <select-menu :title="form.countryName" :width='272'>
              <option-menu v-for="item in countryNameList" :key="item.id" @click="countryName(item)">{{item.name}}</option-menu>
            </select-menu>
          </Validator>
        </div>
      </div>
    </div>
    <div class="textBox">
      <div class="spanBox1">
        <div class="itrmBox">
          <div class="item1">
            <span>組織類型</span>
          </div>
          <div class="item2">
            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.organization" :show="chickShow" @isCheckOkValue="organizationCheck">
              <select-menu :title="form.organization" :width='200'>
                <option-menu v-for="item in organizationList" :key="item.id" @click="organization(item)">{{item.enName}}</option-menu>
              </select-menu>
            </Validator>
          </div>
        </div>
        <div class="itrmBox">
          <div class="item1">
            <span>經紀類型</span>
          </div>
          <div class="item2">
            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.economic" :show="chickShow" @isCheckOkValue="economicCheck">
              <select-menu :title="form.economic" :width='200'>
                <option-menu v-for="item in economicList" :key="item.id" @click="economic(item)">{{item.enName}}</option-menu>
              </select-menu>
            </Validator>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import {CountryList,getDictById} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
export default {
  name:'BorkersFormTwo',
  components: {
    SelectMenu,
    OptionMenu,
    Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      countryNameList:[],
      organizationList:[],
      economicList:[],
      form:{
        countryName:"Select",
        organization:"Select",
        economic:"Select",
      },
      isCheckAllOkForm:{
        countryNameCheck:false,
        organizationCheck:false,
        economicCheck:false,
      },
      isFormOk:false,
    }
  },
  methods:{
    countryName(item){
      this.form.countryName = item.name
    },
    organization(item){
      this.form.organization = item.enName
    },
    economic(item){
      this.form.economic = item.enName
    },
    countryNameListGet(){
      CountryList().then(res =>{
        this.countryNameList = res.data.items
      })
    },
    economicListGet(){
      getDictById(15000).then(res =>{
        this.economicList = res.data.list
      })
      getDictById(16000).then(res =>{
        this.organizationList = res.data.list
      })
    },
    countryNameCheck(CheckBoolen){
      this.isCheckAllOkForm.countryNameCheck = CheckBoolen
      this.changPage()
    },
    organizationCheck(CheckBoolen){
      this.isCheckAllOkForm.organizationCheck = CheckBoolen
      this.changPage()
    },
    economicCheck(CheckBoolen){
      this.isCheckAllOkForm.economicCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.countryNameCheck == true 
      && this.isCheckAllOkForm.organizationCheck == true 
      && this.isCheckAllOkForm.economicCheck == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },
  },
  mounted(){
    this.countryNameListGet()
    this.economicListGet()
  }
}
</script>

<style scoped>
.borkersFormTwo{
  display: grid;
  grid-template-rows: 174px 86px 60px 84px;
}
.textBox{
  display: flex;
}
.textBox:nth-child(1){
  grid-row: 2/3;
}
.textBox:nth-child(2){
  grid-row: 4/5;
}
.spanBox1{
  margin: 0 auto;
  position: relative;
  width: 450px;
}
.item1, .item2{
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
}
.item1{
  font-size: 24px;
  margin-bottom: 17px;
}
.item2{
  font-size: 18px;
}
.itrmBox{
  height: 100%;
  display: inline-block;
}
.itrmBox:nth-child(1){
  margin-right: 38px;
}
</style>
